'use client';

import type { ReactNode } from 'react';
import React from 'react';
import type { ButtonSizes } from '$src-components/atoms/Button';
import { Button, DesignType } from '$src-components/atoms/Button';
import { AnalyticsServiceFieldKey } from '$util/analytics/analyticsService/types';
import { sendCalloutCardContactUsEvents } from '@/util/internalAnalytics/client';
import styles from './index.module.scss';

interface Props {
  readonly href: string;
  readonly iconId?: string;
  readonly linkLabel: string;
  readonly designType?: DesignType;
  readonly size?: ButtonSizes;
  readonly trackAsContactCard?: boolean;
}

export function CalloutCardCTALink({
  href,
  iconId,
  linkLabel,
  designType,
  size,
  trackAsContactCard,
}: Props): ReactNode {
  return (
    <Button
      className={styles.mainCta}
      href={href}
      designType={designType ?? DesignType.PRIMARY}
      size={size ?? 'L'}
      iconId={iconId}
      noIcon={!iconId}
      onClick={() => {
        if (trackAsContactCard) {
          sendCalloutCardContactUsEvents(AnalyticsServiceFieldKey.CALLOUT_CARD_PHONE_CLICKED);
        }
      }}
    >
      {linkLabel}
    </Button>
  );
}

export function CalloutCardFirstLink({
  href,
  linkLabel,
  iconId,
  designType,
}: Omit<Props, 'trackAsContactCard' | 'size'>): ReactNode {
  return (
    <Button
      href={href}
      designType={designType ?? DesignType.SECONDARY_LIGHT}
      size="M"
      iconId={iconId}
      noIcon={!iconId}
    >
      {linkLabel}
    </Button>
  );
}

export function CalloutCardSecondLink({
  href,
  linkLabel,
  iconId,
  designType,
  trackAsContactCard,
}: Omit<Props, 'size'>): ReactNode {
  return (
    <Button
      href={href}
      designType={designType ?? DesignType.SECONDARY_LIGHT}
      size="M"
      iconId={iconId}
      noIcon={!iconId}
      onClick={() => {
        if (trackAsContactCard)
          sendCalloutCardContactUsEvents(AnalyticsServiceFieldKey.CALLOUT_CARD_EMAIL_CLICKED);
      }}
    >
      {linkLabel}
    </Button>
  );
}
