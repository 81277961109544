'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Locale } from '@fxtr/i18n';
import classNames from 'classnames';
import { Card } from '@/components/atoms/Card';
import type { MandatoryProp } from '@/types/react';
import { Img } from '@/components/atoms/Img';
import { Icon } from '@/components/atoms/Icon';
import { VrmLookupDumbModal } from '../VrmLookupDumbModal';
import { VrmLookupDumb } from '../VrmLookupDumb';
import type { VrmLookupModalTranslations } from '../VrmLookupDumbModal/translations';
import type { VrmLookupFormTranslations } from '../VrmLookupDumb/translations';

import styles from './index.module.scss';

export interface ServiceCardProps {
  readonly locale: Locale;
  readonly sku: string;
  readonly campaignId: MandatoryProp<string>;
  readonly modalTranslations: VrmLookupModalTranslations;
  readonly formTranslations: VrmLookupFormTranslations;
  readonly title: ReactNode;
  readonly text: ReactNode;
  readonly icon: string;
  readonly className?: string;
}

export function ServiceCard({
  locale,
  sku,
  campaignId,
  modalTranslations,
  formTranslations,
  title,
  text,
  icon,
  className,
}: ServiceCardProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card
        className={classNames(styles.ServiceCard, className)}
        elevation={1}
        cornerRadius={3}
        onClick={() => setShowModal(true)}
      >
        <Img provider="cloudinary" src={icon} alt={sku} width={48} height={48} />
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
        </div>
        <Icon
          className={styles.arrow}
          id="arrows/arrow-circle-broken-right"
          legacy={false}
          width={20}
          height={20}
        />
      </Card>
      <VrmLookupDumbModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        triggeredFrom={`OurPopularServices:${sku}`}
        {...modalTranslations}
      >
        <VrmLookupDumb
          sku={sku}
          locale={locale}
          activeTab="REPAIRS"
          campaignId={campaignId}
          {...formTranslations}
        />
      </VrmLookupDumbModal>
    </>
  );
}
