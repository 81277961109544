'use client';

import type { ReactNode } from 'react';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';

interface CmsOurPopularServicesAbTestProps {
  original: ReactNode;
  bVariant: ReactNode;
}

export function CmsOurPopularServicesAbTest({
  original,
  bVariant,
}: CmsOurPopularServicesAbTestProps): ReactNode {
  const isServicesBVariant = useActiveExperiment(ActiveExperiments.HomepageServicesComponentFR, 'B');
  const isHeroBVariant = useActiveExperiment(ActiveExperiments.HomepageHeroNavServicesUK, 'B');
  if ((isServicesBVariant || isHeroBVariant) && bVariant) return bVariant;
  return original;
}
