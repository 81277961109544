'use client';

import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import type { ImageData, LinkData } from '$util/types';
import { Heading } from '$quantum/atoms/Heading';
import { usePreviousValue } from '$util/hooks/usePreviousValue';
import { Img } from '@/components/atoms/Img';
import Collapse from '@/components/molecules/Collapse';

import styles from './index.module.scss';

export interface AccordionCta extends LinkData {
  readonly title?: string;
  /**
   * @default neutral100
   */
  readonly backgroundColor?: string;
}

export interface AccordionItem {
  readonly title?: string;
  readonly content?: ReactNode;
  readonly icon?: ImageData;
}

export interface AccordionProps {
  readonly accordionItems: AccordionItem[];
  readonly cta?: AccordionCta;

  /**
   * @default false
   */
  readonly openFirstItem?: boolean;
  readonly onChangeHandler?: (index: number) => void;
  readonly className?: string;
}

function AnchorIcon() {
  /* using <Icon /> provides too small icon size */
  return (
    <svg
      className="cta-link-icon"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5357 11.7887C12.536 12.2017 12.2024 12.5372 11.7895 12.5393L11.4948 12.5407C11.0793 12.5428 10.7414 12.2067 10.7411 11.7913L10.7387 8.52439L6.62671 12.6364C6.33382 12.9293 5.85895 12.9293 5.56605 12.6364L5.36439 12.4347C5.0715 12.1418 5.0715 11.6669 5.36439 11.374L9.47637 7.26206L6.20947 7.25969C5.79401 7.25939 5.45795 6.92141 5.46003 6.50595L5.4615 6.21018C5.46356 5.79765 5.79841 5.46422 6.21095 5.46392L11.7812 5.45988C12.1954 5.45958 12.5314 5.79512 12.5317 6.20934L12.5357 11.7887Z"
        fill={styles.neutral600}
      />
    </svg>
  );
}

export const Accordion = function Accordion({
  accordionItems,
  cta,
  openFirstItem = false,
  onChangeHandler,
  className,
}: AccordionProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const defaultActiveKey = openFirstItem ? 0 : undefined;
  const [activeElementKey, setActiveElementKey] = useState<number | undefined>(defaultActiveKey);
  const prevActiveElementKey = usePreviousValue(activeElementKey);

  useEffect(() => {
    if (prevActiveElementKey !== undefined) {
      const prevActiveHeader = containerRef.current?.querySelector<HTMLDivElement>(
        `.ant-collapse-item:nth-child(${prevActiveElementKey}) .ant-collapse-header`
      );
      prevActiveHeader?.blur();
    }
  }, [prevActiveElementKey]);

  const onChange = (key: number) => {
    setActiveElementKey(key === activeElementKey ? undefined : key);
    onChangeHandler?.(key);
  };

  return (
    <div ref={containerRef} className={classNames('Accordion', styles.accordionContainer, className)}>
      {accordionItems.map(({ title, content, icon }, i) => {
        return (
          <Collapse
            // eslint-disable-next-line react/no-array-index-key -- items will not change order
            key={i}
            expanded={i === activeElementKey}
            onExpand={() => onChange(i)}
            className="accordion-item"
            title={
              <div className={styles.collapseTitleWrapper}>
                {icon && (
                  <Img
                    className={styles.collapseTitleIcon}
                    provider="cloudinary"
                    src={icon.url}
                    alt={icon.alt}
                    width={icon.width || 28}
                    height={icon.height || 28}
                  />
                )}
                <span className={styles.collapseTitleText}>{title}</span>
              </div>
            }
            style={{
              marginBottom: 16,
              backgroundColor: styles.white,
              borderRadius: 12,
            }}
          >
            <div className={styles.content}>{content}</div>
          </Collapse>
        );
      })}
      {cta && (
        <div className={styles.cta} style={{ backgroundColor: cta.backgroundColor ?? styles.neutral100 }}>
          {cta.title && <Heading className={styles.ctaTitle}>{cta.title}</Heading>}
          <a href={cta.url} className={styles.ctaLink} target="_blank">
            <Heading>{cta.text}</Heading>
            <AnchorIcon />
          </a>
        </div>
      )}
    </div>
  );
};

Accordion.displayName = 'Accordion';
