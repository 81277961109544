// import 'server-only';

import { Locale } from '@fxtr/i18n';
import type { ReactNode } from 'react';
import type { TranslationDictionary } from '@/types/components/translations';

export interface VrmLookupModalTranslations {
  readonly toggleButtonText: string;
  readonly heading: ReactNode;
  readonly description?: string;
}

export const translations = {
  [Locale.EN_GB]: {
    default: {
      toggleButtonText: 'Get a quote',
      heading: (
        <>
          Get the best price in your
          <br />
          area for your vehicle
        </>
      ),
    },
    seeServices: {
      toggleButtonText: 'See Our Services',
    },
  },
  [Locale.FR_FR]: {
    default: {
      toggleButtonText: 'Obtenir un devis',
      heading: 'Obtenez un devis instantané et gratuit',
    },
    seeServices: {
      toggleButtonText: 'Voir nos services',
    },
    ct: {
      toggleButtonText: 'Prendre RDV',
    },
  },
} satisfies TranslationDictionary<VrmLookupModalTranslations>;
