'use client';

import type { ReactNode } from 'react';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';

interface CmsHeroSectionAbTestProps {
  original: ReactNode;
  bVariant: ReactNode;
}

export function CmsHeroSectionAbTest({ original, bVariant }: CmsHeroSectionAbTestProps): ReactNode {
  const isBVariant = useActiveExperiment(ActiveExperiments.HomepageHeroNavServicesUK, 'B');
  if (isBVariant && bVariant) return bVariant;
  return original;
}
