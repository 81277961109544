'use client';

import { useEffect, useRef, type ReactNode } from 'react';

import styles from './index.module.scss';

export function CmsHeroSectionStickyNav(): ReactNode {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver | undefined;
    document.documentElement.classList.add('sticky-nav', 'transparent-nav');
    if (ref?.current) {
      observer = new IntersectionObserver(
        (entry) => {
          const elm = entry[0];
          if (elm.isIntersecting) {
            if (elm.intersectionRatio > 0) {
              document.documentElement.classList.remove('nav-docked');
              const promoBanner = document.querySelector<HTMLElement>('.TopNavBanner');
              if (promoBanner) {
                const bannerHeight = promoBanner.getBoundingClientRect().height;
                document.documentElement.style.setProperty(
                  '--nav-scroll',
                  `${(bannerHeight / 100) * Math.round(elm.intersectionRatio * 100)}px`
                );
              }
            }
          } else {
            document.documentElement.style.setProperty('--nav-scroll', '0px');
            document.documentElement.classList.add('nav-docked');
          }
          // force a reflow - needed to flush the state of the DOM to ensure the
          // intersection ratio is correcly calculated - this is not guaranteed
          // however and may still result in a stuck promo banner on some occasions
          elm.target.getBoundingClientRect();
        },
        {
          threshold: Array.from({ length: 98 }, (_, i) => i / 100),
        }
      );

      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [ref]);
  return <div ref={ref} className={styles.intersector} />;
}
