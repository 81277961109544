import config from 'config';
import { isBrowser } from '$util/index';

import { AnalyticsEvents } from './types';
import { googleAnalytics4 } from './googleAnalytics4';

const gaEnabled = isBrowser && config.get('public.ga4.enabled');

/**
 * @deprecated - This doesn't help preserve type safety has is lead us to some issues. See noopWhenGAIsOff instead
 */
export const recordAnalytics = (eventType: string, data?: any): void => {
  if (!gaEnabled) return;
  switch (eventType) {
    case AnalyticsEvents.VEHICLE_LOOKUP_SUCCESS:
      googleAnalytics4.vehicleLookupSuccess(data);
      break;
    case AnalyticsEvents.VEHICLE_LOOKUP_ERROR:
      googleAnalytics4.vehicleLookupError(data);
      break;
    case AnalyticsEvents.PRODUCT_DISPLAYED:
      googleAnalytics4.productDisplayed(data);
      break;
    case AnalyticsEvents.MOT_SERVICES_TAB_DISPLAYED:
      googleAnalytics4.motServicesTabDisplayed();
      break;
    case AnalyticsEvents.SERVICES_TAB_DISPLAYED:
      googleAnalytics4.servicesTabDisplayed();
      break;
    case AnalyticsEvents.DIAGNOSTICS_TAB_DISPLAYED:
      googleAnalytics4.diagnosticsTabDisplayed();
      break;
    case AnalyticsEvents.REPAIRS_TAB_DISPLAYED:
      googleAnalytics4.repairsTabDisplayed();
      break;
    case AnalyticsEvents.ADD_TO_BASKET:
      googleAnalytics4.addToBasket(data);
      break;
    case AnalyticsEvents.REMOVE_FROM_BASKET:
      googleAnalytics4.removeFromBasket(data);
      break;
    case AnalyticsEvents.ADD_COUPON:
      googleAnalytics4.applyPromotion(data);
      break;
    case AnalyticsEvents.CALLBACK_SUCCESS:
      googleAnalytics4.callbackRequested();
      break;
    case AnalyticsEvents.VALUE_PROPOSITION_VIDEO_CLICKED:
      googleAnalytics4.valuePropositionVideoClicked();
      break;
    case AnalyticsEvents.GET_INSTANT_QUOTE_MODAL_CLICKED:
      googleAnalytics4.getInstantQuoteModalClicked();
      break;
    case AnalyticsEvents.OFFLINE_QUOTE_REQUEST_DISPLAYED:
      googleAnalytics4.offlineQuoteModalDisplayed();
      break;
    case AnalyticsEvents.PRICE_RANGE_DISPLAYED:
      googleAnalytics4.quoteIncludingPriceRange();
      break;
    case AnalyticsEvents.SAVE_QUOTE_BANNER_DISPLAYED:
      googleAnalytics4.saveQuoteBannerDisplayed();
      break;
    case AnalyticsEvents.SAVE_QUOTE_BANNER_DISMISSED:
      googleAnalytics4.saveQuoteBannerDismissed();
      break;
    case AnalyticsEvents.SAVE_QUOTE_BANNER_SUBMITTED:
      googleAnalytics4.saveQuoteBannerSubmitted();
      break;
    case AnalyticsEvents.DOWNLOAD_SERVICE_SHEET_CLICKED:
      googleAnalytics4.downloadServiceSheetClicked();
      break;
    case AnalyticsEvents.QUOTES_PAGE_DISPLAYED:
      googleAnalytics4.quotesPageDisplayed(data);
      break;
    case AnalyticsEvents.SCHEDULE_PAGE_DISPLAYED:
      googleAnalytics4.schedulePageDisplayed();
      break;
    case AnalyticsEvents.COLLECTION_TIME_DISPLAYED:
      googleAnalytics4.collectionTimeDisplayed(data);
      break;
    case AnalyticsEvents.PERSONAL_DETAILS_PAGE_DISPLAYED:
      googleAnalytics4.personalDetailsPageDisplayed();
      break;
    case AnalyticsEvents.PAYMENT_PAGE_DISPLAYED:
      googleAnalytics4.paymentPageDisplayed();
      break;
    case AnalyticsEvents.SUCCESS_PAGE_DISPLAYED:
      googleAnalytics4.successPageDisplayed(data);
      break;
    case AnalyticsEvents.ADD_TO_CALENDAR_CLICKED:
      googleAnalytics4.addToCalendarClicked(data);
      break;
    case AnalyticsEvents.BUNDLE_PAGE_DISPLAYED:
      googleAnalytics4.bundlePageDisplayed(data);
      break;
    case AnalyticsEvents.BUNDLE_PAGE_ADDED:
      googleAnalytics4.bundlePageAdded(data);
      break;
    case AnalyticsEvents.BUNDLE_PAGE_SKIPPED:
      googleAnalytics4.bundlePageSkipped(data);
      break;
    case AnalyticsEvents.SALES_MODAL:
      googleAnalytics4.salesModalClicked(data);
      break;
    case AnalyticsEvents.SALES_MODAL_CTA:
      googleAnalytics4.salesModalCtaClicked(data);
      break;
    case AnalyticsEvents.FOOTER_FAQ_CLICKED:
      googleAnalytics4.footerFaqClicked();
      break;
    case AnalyticsEvents.OPTIONAL_CnD_NO_ELIGIBLE_GARAGES:
      googleAnalytics4.optionalCnDNoEligibleGarages(data);
      break;
    case AnalyticsEvents.CnD_3_ELIGIBLE_GARAGES:
      googleAnalytics4.cnD3EligibleGarages();
      break;
    case AnalyticsEvents.CnD_2_ELIGIBLE_GARAGES:
      googleAnalytics4.cnD2EligibleGarages();
      break;
    case AnalyticsEvents.CnD_1_ELIGIBLE_GARAGES:
      googleAnalytics4.cnD1EligibleGarages();
      break;
    case AnalyticsEvents.CnD_3_TOTAL_GARAGES:
      googleAnalytics4.cnD3TotalGarages();
      break;
    case AnalyticsEvents.CnD_2_TOTAL_GARAGES:
      googleAnalytics4.cnD2TotalGarages();
      break;
    case AnalyticsEvents.CnD_1_TOTAL_GARAGES:
      googleAnalytics4.cnD1TotalGarages();
      break;
    case AnalyticsEvents.OPTIONAL_CnD_AVERAGE_DISTANCE_ELIGIBLE_GARAGES:
      googleAnalytics4.optionalCnDAverageDistanceEligibleGarages(data);
      break;
    case AnalyticsEvents.OPTIONAL_CnD_CLOSEST_DISTANCE_ELIGIBLE_GARAGES:
      googleAnalytics4.optionalCnDClosestDistanceEligibleGarages(data);
      break;
    case AnalyticsEvents.GOOGLE_REVIEWS_SUMMARY_CLICKED:
      googleAnalytics4.googleReviewsSummaryClicked(null);
      break;
    case AnalyticsEvents.NEED_HELP_BUTTON_CLICKED:
      googleAnalytics4.needHelpClicked(null);
      break;
    case AnalyticsEvents.NEED_HELP_MODAL_CALL_US_CLICKED:
      googleAnalytics4.needHelpModalCallUsClicked(null);
      break;
    case AnalyticsEvents.NEED_HELP_MODAL_EMAIL_US_CLICKED:
      googleAnalytics4.needHelpModalEmailUsClicked(null);
      break;
    case AnalyticsEvents.NEED_HELP_MODAL_CONTACT_FORM_CLICKED:
      googleAnalytics4.needHelpModalContactFormClicked(null);
      break;
    case AnalyticsEvents.NEED_HELP_MODAL_FAQ_CLICKED:
      googleAnalytics4.needHelpModalFAQClicked(data);
      break;
    case AnalyticsEvents.EDIT_BOOKING_BUTTON_CLICKED:
      googleAnalytics4.editBookingButtonClicked(data);
      break;
    case AnalyticsEvents.DOWNLOAD_INVOICE_BUTTON_CLICKED:
      googleAnalytics4.downloadInvoiceButtonClicked(data);
      break;
    case AnalyticsEvents.DOWNLOAD_SERVICE_SHEET_BUTTON_CLICKED:
      googleAnalytics4.downloadServiceSheetButtonClicked(data);
      break;
    case AnalyticsEvents.VIEW_MOT_CERTIFICATE_BUTTON_CLICKED:
      googleAnalytics4.viewMotCertificateButtonClicked(data);
      break;
    default:
      break;
  }
};
